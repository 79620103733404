@import "typography.scss";

html {
  font-family: "Lato", sans-serif !important;
}

//Scrollbar
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px $scrollbar-background-shadow-color;
  background-color: $scrollbar-background-color;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-color;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.private-sale-loader-container {
  background-color: rgba($color: #ffffff, $alpha: 1);
}

.base-dashboard-loader-container {
  background-color: rgba($color: #ffffff, $alpha: 1);
}

::ng-deep
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: rgba(179, 174, 160, 1);
  box-shadow: 0 0 0 2px rgb(179 174 160 / 20%);
}

::ng-deep .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: rgba(179, 174, 160, 1);
}

::ng-deep .language-select {
  & .ant-select-arrow {
    //color: #fff !important;
    color: #000 !important;
  }

  //color: #fff;
  color: #000 !important;
}

.sale-event-scroll-down-button {
  display: none !important;
}

.booking-sale-detail-banner-container {
  max-height: 500px !important;
  background-position: top center !important;
}

.advanced-booking-sale-detail-banner-container {
  max-height: 500px !important;
  background-position: top center !important;

  & .advanced-booking-sale-detail-banner-dark-layer {
    background-color: transparent !important;
    height: 500px !important;

    & .advanced-sale-event-name {
      display: none !important;
    }
  }
}

.sw-border-radius {
  border-radius: 8px !important;
}

.mat-expansion-panel {
  border-radius: 8px !important;
}


.auth-form-mobile-banner {
  height: 230px;
  width: 100%;
  background-color: transparent !important;
  background-position: top center;
  background-size: contain !important;

  & .auth-form-mobile-banner-opacity-layer {
    background-color: transparent !important;

    & .auth-form-title {
      color: #ffffff;
    }
  }
}

.user-header-container {
  position: relative;
  color: white;
  & .user-menu-language-select {
    margin-left: 18px;
    min-width: 110px !important;
    color: white !important;
    & .ant-select-selector {
      background-color: rgba($color: white, $alpha: 0.05) !important;
      border: none !important;
      align-items: center;
      height: 40px !important;
      padding: 0 11px 0 15px;
      //padding-left: 0px;

      & .ant-select-selection-item {
        font-size: 16px;
        text-transform: capitalize;
        letter-spacing: 1px;
        color: white !important;
        font-weight: 700;
      }
    }
  }
}

.animation-container {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  z-index: 0 !important;
}